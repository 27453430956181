/*
 * @Author: your name
 * @Date: 2021-12-08 09:58:38
 * @LastEditTime: 2021-12-08 10:07:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/ForecastShipping.js
 */
export default {
    path: '/forecastShipping',
    name: "ForecastShipping",
    title: "预报下单",
    hide: false,
    icon: 'container',
    component: resolve => require(['@/views/ForecastShipping'], resolve),
    meta: { title: '预报下单' }
}
