/*
 * @Author: your name
 * @Date: 2021-11-30 10:51:36
 * @LastEditTime: 2021-12-08 14:08:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/index.js
 */
import TabHeader from "./TabHeader"
import chooseAdress from "./chooseAdress"
import chooseWarehouse from "./chooseWarehouse"
import chooseTickets from "./chooseTickets"
import chooseProduct from "./chooseProduct"
import chooseProductName from "./chooseProductName"
import lyDrawer from "./lyDrawer";
import Vgrid from "./Vgrid";
import EditTable from "./EditTable";
export default {
    install(Vue) {
        Vue.mixin({
            components: {
                TabHeader,
                chooseAdress,
                chooseWarehouse,
                chooseTickets,
                chooseProduct,
                chooseProductName,
                lyDrawer,
                Vgrid,
                EditTable
            }
        })
    }
}