export function uuid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}
// 判断是整数 还是 浮点数
export function intOrFloat(n) {
    let val
    if (typeof n === 'number' && parseFloat(n) == parseInt(n, 10) && !isNaN(n)) {
        val = n
    } else if (n === +n && n !== (n | 0)) {
        val = n.toFixed(2)
    } else {
        val = n
    }
    return val;
}