/*
 * @Author: your name
 * @Date: 2021-11-18 17:23:13
 * @LastEditTime: 2021-12-10 11:20:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/getters.js
 */
const getters = {
    getToken(state) {
        return state.token
    },
    getUser(state) {
        return state.user
    },
    getSelectedKeys(state) {
        return state.selectedKeys
    },
    getOpenKeys(state) {
        return state.openKeys
    },
    getTagViews(state) {
        return state.tagViews
    },
    getWaybillIndex(state) {
        return state.waybillIndex
    },
    getAddressIndex(state) {
        return state.addressIndex 
    },
    getLoginStep(state) {
        return state.loginStep 
    }
}

export default getters