/*
 * @Author: your name
 * @Date: 2021-11-18 17:30:38
 * @LastEditTime: 2021-12-08 10:31:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/index.js
 */
import Vue from "vue";
import Router from "vue-router";
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);
import ForecastShipping from "./ForecastShipping";
import ConfigInfo from "./ConfigInfo";
import Waybill from "./Waybill";
import Billing from "./Billing";
import MySettings from "./MySettings";
import Layout from "@/views/Layout";
import ReturnWaybill from "./ReturnWaybill";
import FeedBack from "./FeedBack";
import ForecastManagement from "./ForecastManagement";
const router = [
  {
    path: "/",
    name: "Layout",
    title: "外框",
    component: Layout,
    redirect: "/forecastShipping",
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "/DataStatistics",
        name: "DataStatistics",
        title: "数据统计",
        icon: "container",
        hide: false,
        redirect: "/DataStatistics/CargoNum",
        component: (resolve) => require(["@/views/DataStatistics"], resolve),
        meta: {
          title: "数据统计",
        },
        children: [
          {
            path: "/DataStatistics/CargoNum",
            name: "CargoNum",
            title: "货量统计",
            icon: "container",
            hide: false,
            component: (resolve) =>
              require(["@/views/DataStatistics/CargoNum"], resolve),
            meta: {
              title: "货量统计",
            },
          },
          {
            path: "/DataStatistics/FreightStatistics",
            name: "FreightStatistics",
            title: "运费统计",
            icon: "container",
            hide: false,
            component: (resolve) =>
              require(["@/views/DataStatistics/FreightStatistics"], resolve),
            meta: {
              title: "运费统计",
            },
          },
          {
            path: "/DataStatistics/Limitation",
            name: "Limitation",
            title: "时效统计",
            icon: "container",
            hide: false,
            component: (resolve) =>
              require(["@/views/DataStatistics/Limitation"], resolve),
            meta: {
              title: "时效统计",
            },
          },
        ],
      },
      ForecastShipping,
      ...ForecastManagement,
      ...Waybill,
      ConfigInfo,
      Billing,
      FeedBack,
      {
        path: "/SystemBulletin",
        name: "CargoNum",
        title: "公告",
        icon: "container",
        hide: false,
        component: (resolve) => require(["@/views/SystemBulletin"], resolve),
        meta: {
          title: "货量统计",
        },
      },
      ReturnWaybill,
      MySettings,
      {
        path: "/WaybillNoManagement",
        name: "WaybillNoManagement",
        title: "运单号管理",
        icon: "container",
        hide: false,
        component: (resolve) =>
          require(["@/views/waybillNoManagement"], resolve),
        meta: {
          title: "运单号管理",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    title: "登录",
    component: (resolve) => require(["@/views/Login"], resolve),
  },
  {
    path: "/trackingQuery",
    name: "TrackingQuery",
    title: "物流信息查询",
    component: (resolve) => require(["@/views/TrackingQuery"], resolve),
  },
];

export default new Router({
  mode: "history",
  routes: router,
  scrollBehavior(to, from, savedPosition) {
    console.log(savedPosition);
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log("savedPosition", savedPosition);
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
