<!--
 * @Author: your name
 * @Date: 2021-11-22 11:22:06
 * @LastEditTime: 2021-11-23 11:18:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Layout/yMenu/index.vue
-->
<template>
    <a-menu :defaultSelectedKeys="defaultSelectedKeys" :selectedKeys="selectedKeys" mode="inline"
        :open-keys.sync="openKeys" :inline-collapsed="collapsed" @click="menuClick" @select="handlerSelect"
        @openChange="handlerOpen">
        <template v-for="item in list">
            <sub-menu v-if="(item.children && item.children.length > 0 && !item.hide && item.title!='数据统计')" :key="item.path"
                :menu-info="item" />
            <a-menu-item v-else-if="!item.hide" :key="item.path">
                <a-icon v-if="item.icon" :type="item.icon" />
                <span>{{ item.title }}</span>
            </a-menu-item>
        </template>
    </a-menu>
</template>

<script>
import ost from "../ost";
import { Menu } from 'ant-design-vue';
const SubMenu = {
    template: `
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon v-if="menuInfo.icon" :type="menuInfo.icon" /><span>{{ menuInfo.title }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children && !item.hide" :key="item.path">
            <a-icon v-if="item.icon" :type="item.icon" />
            <span>{{ item.title }}</span>
          </a-menu-item>
          <sub-menu v-if="item.children && item.children.length > 0" :key="item.path" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
    name: 'SubMenu',
    // must add isSubMenu: true 此项必须被定义
    isSubMenu: true,
    props: {
        // 解构a-sub-menu的属性，也就是文章开头提到的为什么使用函数式组件
        ...Menu.SubMenu.props,
        // Cannot overlap with properties within Menu.SubMenu.props
        menuInfo: {
            type: Object,
            default: () => ({}),
        },
    },
};
export default {
    name: "yMenu",
    props: {
        csd: {
            type: Boolean,
            default: false,
            indexPath: 0
        }
    },
    watch: {
        csd: {
            handler(val) {
                this.collapsed = val
            },
            immediate: true
        },
        $route: {
            handler(val) {
                let secPath = [val.path];
                this.selectedKeys = secPath;
                this.setSelectedKeys(secPath);
            },
            immediate: true,
        },

    },
    components: {
        'sub-menu': SubMenu,
    },
    data() {
        return {
            list: ost,
            collapsed: false,
            openKeys: [],
            selectedKeys: [],
            defaultSelectedKeys: []
        };
    },
    created() {
        this.openKeys = this.getOpenKeys;
        this.defaultSelectedKeys = this.getSelectedKeys;
        this.initMenu();
    },
    methods: {
        handlerSelect({ selectedKeys }) {
            this.selectedKeys = selectedKeys;
            this.setSelectedKeys(selectedKeys);

        },
        handlerOpen(val) {
            this.setOpenKeys(val);
        },
        menuClick({ key }) {
            this.$router.push(key)
        },
        initMenu() {
            let routes = this.$router.options.routes || [];
            let farr = routes.filter(ele => {
                return ele.name === 'Layout'
            });
            let arr = farr[0].children || [];
            let menus = [];
            this.routerToMenu(arr, menus);
            this.list = menus;
        },
        // 递归数据
        routerToMenu(arr = [], menus) {
            for (let i = 0; i < arr.length; i++) {
                this.indexPath++
                if (!arr[i].children && !arr[i].hide) {
                    let obj = {
                        key: this.indexPath,
                        title: arr[i].title,
                        path: arr[i].path,
                        hide: arr[i].hide,
                        icon: arr[i].icon || ''
                    }
                    menus.push(obj)
                }
                if (arr[i].children) {
                    let obj = {
                        key: this.indexPath,
                        title: arr[i].title,
                        path: arr[i].path,
                        hide: arr[i].hide || false,
                        icon: arr[i].icon || '',
                        children: []
                    }
                    menus.push(obj);
                    this.routerToMenu(arr[i].children, obj.children)
                }
            }

        }

    }
};
</script>

<style lang="less" scoped>

</style>