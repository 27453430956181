<!--
 * @Author: your name
 * @Date: 2021-12-07 09:59:28
 * @LastEditTime: 2021-12-29 10:26:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/index.vue
-->
<template>
    <div>
        <div><span>{{ content }}</span><a class="btn-a" @click="openTable">{{ title }}</a></div>
        <ly-drawer :title="model.title" :visible.sync="model.visible" :width="model.width"
            :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
            <a-form-model layout="inline" :model="form" v-if="type === 'fba'">
                <a-form-model-item label="国家">
                    <a-select show-search v-model="form.countryId" placeholder="选择国家" style="width: 200px"
                        :default-active-first-option="false" :show-arrow="true" :filter-option="false"
                        :not-found-content="null" @search="searchCountry" @change="handleChange">
                        <a-select-option v-for="c in countryList" :key="c.id" :value="c.id">
                            {{ c.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item>
                    <a-button-group>
                        <a-button type="primary" @click="search">搜索</a-button>
                        <a-button @click="reset">重置</a-button>
                    </a-button-group>
                </a-form-model-item>
            </a-form-model>
            <a-table size="small" style="margin-top: 20px" :customRow="rowClick" :rowSelection="rowSelection" bordered
                :columns="columns" :data-source="tableData" :loading="tableLoading" :pagination="pagination" :rowKey="(record,index) => record.id"
                @change="handlerPage"></a-table>
        </ly-drawer>
    </div>
</template>

<script>
import { storehouseFbaLists, storehouseLists, countryList } from "@/api/comm";
import { storehouseFbaInfo } from "@/api/Address";
import columns from "./columns";
export default {
    name: "chooseWarehouse",
    props: {
        type: {
            type: [String, Boolean],
            default: false,
        },
        warehouseId: {
            type: [String, Number],
            default: "",
        },
    },
    model: {
        prop: "warehouseId",
        event: "returnWarehouse",
    },
    watch: {
        type: {
            handler(val) {
                this.content = "";
                this.selectedRowKeys = [];
                if (!val) {
                    this.title = "选择仓库";
                    this.model.title = "选择仓库";
                    this.apiUrl = storehouseLists;
                    this.columns = columns.a;
                } else if (val === "fba") {
                    this.title = "选择FBA仓库";
                    this.model.title = "选择FBA仓库";
                    this.apiUrl = storehouseFbaLists;
                    this.columns = columns.b;
                }
            },
            immediate: true,
        },
        warehouseId: {
            handler(val) {
                if (val) {
                    if (!this.type) {
                        storehouseLists({ page: 1, limit: 10000 }).then((res) => {
                            const arr = res.data.list || [];
                            let row = arr.find((ele) => ele.id === val);
                            this.content = `${row.name} - (${row.address}) `;
                        });
                    } else if (this.type === "fba") {
                        storehouseFbaInfo({ fbaId: val }).then((res) => {
                            let row = res.data.data;
                            this.content = `${row.country_name} - ${row.name} - (${row.address}) `;
                        });
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        rowSelection() {
            return {
                type: "radio",
                columnTitle: "选择",
                columnWidth: 70,
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(
                        `selectedRowKeys: ${selectedRowKeys}`,
                        "selectedRows: ",
                        selectedRows
                    );
                    this.selectedRowKeys = selectedRowKeys;
                    let row = selectedRows[0];
                    if (!this.type) {
                        this.content = `${row.name} - (${row.address}) `;
                    } else if (this.type === "fba") {
                        this.content = `${row.country_name} - ${row.name} - (${row.address}) `;
                    }
                },
            };
        },
    },
    data() {
        return {
            content: "",
            title: "选择地址",
            apiUrl: null,
            confirmLoading: false,
            tableLoading: false,
            selectedRowKeys: [],
            model: {
                title: "",
                visible: false,
                width: "800px",
            },
            countryList: [],
            form: {
                countryId: "",
                limit: 10,
                page: 1,
            },
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            tableData: [],
            columns: [],
        };
    },
    created() {
        this.searchCountry("");
    },
    methods: {
        openTable() {
            if (!this.type) {
                this.getData().then(() => {
                    this.model.visible = true;
                });
            } else {
                this.model.visible = true;
            }
        },
        // 获取国家列表
        searchCountry(val) {
            let obj = {
                name: val,
                page: 1,
                limit: 10000,
            };
            countryList(obj).then((res) => {
                this.countryList = res.data.list;
            });
        },
        handleChange(val) {
            this.form.countryId = val;
        },
        getData() {
            return new Promise((resolve, reject) => {
                this.tableLoading = true;
                this.apiUrl(this.form)
                    .then((res) => {
                        const arr = res.data.list || [];
                        this.tableData = this.sortArr(arr, 'name');
                        this.pagination.total = res.data.total;
                        this.model.visible = true;
                        this.tableLoading = false;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            });
        },
        handleOk() {
            this.$emit("returnWarehouse", this.selectedRowKeys.toString() * 1);
            this.$emit("change", this.selectedRowKeys[0]);
            this.model.visible = false;
        },
        handleCancel() {
            this.model.visible = false;
        },
        reset() {
            this.form.keyword = "";
        },
        search() {
            this.form.page = 1;
            this.getData();
        },
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        //table行点击
        rowClick(record) {
            return {
                on: {
                    click: () => {
                        this.selectedRowKeys = [record.id];
                        if (!this.type) {
                            this.content = `${record.name} - (${record.address}) `;
                        } else if (this.type === "fba") {
                            this.content = `${record.country_name} - ${record.name} - (${record.address}) `;
                        }
                    },
                },
            };
        },
    },
};
</script>
