<!--
 * @Author: your name
 * @Date: 2021-12-07 09:59:28
 * @LastEditTime: 2021-12-28 16:23:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/index.vue
-->
<template>
    <div>
        <div><span>{{ content }}</span><a class="btn-a" v-if="!disabled" @click="openTable">选择产品</a></div>
        <a-modal :title="model.title" :visible="model.visible" :width="model.width" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel" :maskClosable="false">
            <a-form-model layout="inline" :model="form">
                <a-form-model-item label="商品名称">
                    <a-input v-model="form.goodsName" />
                </a-form-model-item>
                <a-form-model-item label="产品分类">
                    <a-select v-model="form.categoryIds" mode="multiple" placeholder="产品分类" style="width: 180px">
                        <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item>
                    <a-button-group>
                        <a-button type="primary" @click="search">搜索</a-button>
                        <a-button @click="reset">重置</a-button>
                    </a-button-group>
                </a-form-model-item>
            </a-form-model>
            <a-table size="small" style="margin-top: 20px" :customRow="rowClick" :rowSelection="rowSelection" bordered
                :columns="columns" :data-source="tableData" :loading="tableLoading" :pagination="pagination" :rowKey="(record,index) => record.id"
                @change="handlerPage">
                <template slot="declareds" slot-scope="text">
                    <span>{{ text.length > 0 ? text[0].currency_name || "-" : '-' }}</span>
                    <a-popover placement="bottom">
                        <template slot="content">
                            <p v-for="(ix, n) in text" :key="n">
                                <span>{{ ix.currency_name }}</span><span>({{ ix.declared_value }})</span></p>
                        </template>
                        <template slot="title">
                            <span>货币</span>
                        </template>
                        <a class="btn-a" style="margin-left: 10px">查看</a>
                    </a-popover>
                </template>
                <!-- <template slot="materialCates" slot-scope="text">
                    <span v-if="text.length > 0">{{text[0].name || "-"}}</span>
                    <a-popover placement="bottom" v-if="text.length > 0">
                        <template slot="content">
                            <p v-for="(ix,n) in text" :key="n"><span>{{ix.name}}</span></p>
                        </template>
                        <template slot="title">
                            <span>材质</span>
                        </template>
                        <a class="btn-a" style="margin-left: 10px">查看</a>
                    </a-popover>
                    <span v-if="text.length === 0">-</span>
                </template> -->
                <template slot="itemArr" slot-scope="text">
                    <span>{{ text.zh_name || "-" }}</span>
                    <a-popover placement="bottom">
                        <template slot="content">
                            <p><span>{{ text.zh_name }}</span></p>
                        </template>
                        <template slot="title">
                            <span>其他</span>
                        </template>
                        <a class="btn-a" style="margin-left: 10px">查看</a>
                    </a-popover>
                </template>
            </a-table>
        </a-modal>
    </div>
</template>

<script>
import { productList, productCategory } from "@/api/comm";
import { productInfo } from "@/api/Product";
import columns from "./columns";
export default {
    name: "chooseProduct",
    props: {
        productId: {
            type: [String, Number],
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: "productId",
        event: "returnProduct",
    },
    watch: {
        productId: {
            handler(val) {
                if (val) {
                    productInfo({ productId: val }).then(res => {
                        let row = res.data;
                        this.productData = res.data
                        this.content = `${row.zh_name} `;
                    })
                }
            },
            immediate: true
        }
    },
    computed: {
        rowSelection() {
            return {
                type: "radio",
                columnTitle: "选择",
                columnWidth: 70,
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(
                        `selectedRowKeys: ${selectedRowKeys}`,
                        "selectedRows: ",
                        selectedRows
                    );
                    this.selectedRowKeys = selectedRowKeys;
                    let row = selectedRows[0];
                    this.content = `${row.zh_name} `;
                },
            };
        },
    },
    data() {
        return {
            content: "",
            apiUrl: productList,
            confirmLoading: false,
            tableLoading: false,
            selectedRowKeys: [],
            categoryList: [],
            productData: "",
            model: {
                title: "",
                visible: false,
                width: "1390px",
            },
            form: {
                goodsName: "",
                categoryIds: [],
                limit: 20,
                page: 1,
            },
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            tableData: [],
            columns: columns,
        };
    },
    methods: {
        // 分类列表
        productCategory() {
            productCategory().then((res) => {
                const list = res.data;
                this.categoryList = list;
            });
        },
        openTable() {
            this.productCategory();
            this.getData().then(() => {
                this.model.visible = true;
            });
        },
        getData() {
            return new Promise((resolve, reject) => {
                this.tableLoading = true;
                this.apiUrl(this.form)
                    .then((res) => {
                        this.tableData = res.data.list;
                        this.model.visible = true;
                        this.tableLoading = false;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            });
        },
        handleOk() {
            this.$emit("returnProduct", this.selectedRowKeys.toString() * 1);
            // this.$emit("change", this.selectedRowKeys[0]);
            this.$emit("change", this.productData)
            this.model.visible = false;
        },
        handleCancel() {
            this.model.visible = false;
        },
        reset() {
            this.form.goodsName = "";
            this.form.categoryIds = [];
        },
        search() {
            this.form.page = 1;
            this.getData();
        },
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        //table行点击
        rowClick(record) {
            return {
                on: {
                    click: () => {
                        this.selectedRowKeys = [record.id]
                    },
                },
            };
        },
    },
};
</script>
