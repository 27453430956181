<!--
 * @Author: your name
 * @Date: 2021-12-07 09:59:28
 * @LastEditTime: 2021-12-29 10:10:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/index.vue
-->
<template>
    <div>
        <div><span>{{ content }}</span><a class="btn-a" @click="openTable">{{ title }}</a></div>
        <ly-drawer :title="model.title" :visible="model.visible" :width="model.width" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel">
            <a-form-model layout="inline" :model="form">
                <a-form-model-item label="地址名称">
                    <a-input v-model="form.keyword" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button-group>
                        <a-button type="primary" @click="search">搜索</a-button>
                        <a-button @click="reset">重置</a-button>
                    </a-button-group>
                </a-form-model-item>
            </a-form-model>
            <a-table size="small" style="margin-top: 20px" :customRow="rowClick" :rowSelection="{type: 'radio',columnTitle: '选择',columnWidth: 70,selectedRowKeys: selectedRowKeys,onChange: onSelectChange}" bordered
                :columns="columns" :data-source="tableData" :loading="tableLoading" :rowKey="(record, index) => record.id"
                :pagination="pagination" @change="handlerPage"></a-table>
        </ly-drawer>
    </div>
</template>

<script>
import {
    noFbaAddress,
    fbaAddress,
    addressList
} from "@/api/comm";
import {
    addressUpdate
} from "@/api/Waybill"
import columns from "./columns";
export default {
    name: "chooseAdress",
    props: {
        edit: {
            type: Boolean,
            default: false
        },
        type: {
            type: [String, Boolean, Number],
            default: false,
        },
        addressId: {
            type: [String, Number],
            default: "",
        },
    },
    model: {
        prop: "addressId",
        event: "returnAddress",
    },
    watch: {
        addressId: {
            handler(val) {
                if (!val) {
                    this.content = ''
                }
            },
            immediate: true
        },
        type: {
            handler(val) {
                this.selectedRowKeys = [];
                if (!val) {
                    this.title = "选择地址";
                    this.model.title = "选择地址";
                    this.apiUrl = addressList;
                    this.columns = columns.a;
                } else if (val === "fba") {
                    this.title = "选择FBA地址";
                    this.model.title = "选择FBA地址";
                    this.apiUrl = fbaAddress;
                    this.columns = columns.b;
                } else {
                    this.title = "选择非FBA地址";
                    this.model.title = "选择非FBA地址";
                    this.apiUrl = noFbaAddress;
                    this.columns = columns.c;
                }
            },
            immediate: true,
        },
        "form.keyword": (val) => {
            this.form.storehouseName = val;
        }
    },
    data() {
        return {
            content: "",
            x: 0,
            title: "选择地址",
            apiUrl: null,
            confirmLoading: false,
            tableLoading: false,
            selectedRowKeys: [],
            model: {
                title: "",
                visible: false,
                width: "1200px",
            },
            form: {
                keyword: "",
                storehouseName: "",
                limit: 20,
                page: 1,
            },
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            tableData: [],
            columns: [],
            chooseRow: {}
        };
    },
    methods: {
        openTable() {
            this.getData().then(() => {
                this.model.visible = true;
            });
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
            this.selectedRowKeys = selectedRowKeys;
            let row = selectedRows[0];
            this.chooseRow = row;
            if (!this.type) {
                this.content = `${row.name} - (${row.address}) `;
            } else if (this.type === "fba") {
                this.content = `${row.country_name} - ${row.fba_name} - (${row.address}) `;
            } else {
                this.content = `${row.country_name} - ${row.name} - (${row.address}) `;
            }
        },
        getData() {
            return new Promise((resolve, reject) => {
                this.tableLoading = true;
                this.apiUrl(this.form)
                    .then((res) => {
                        if (this.type === 'fba') {
                            let arr = res.data.list || [];
                            arr.sort(function (a, b) {
                                return a.fba_name > b.fba_name
                            })
                            this.tableData = arr;

                        } else {
                            this.tableData = res.data.list;
                        }
                        this.model.visible = true;
                        this.tableLoading = false;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            });
        },
        handleOk() {
            this.$emit("returnAddress", this.selectedRowKeys.toString() * 1);
            this.$emit("change", this.selectedRowKeys[0]);
            this.$emit('getRow', this.chooseRow);
            if (this.edit == true) {
                this.$emit('editAddress', true)
            }
            this.model.visible = false;
        },
        editAddress(id) {
            return new Promise((res, rej) => {
                addressUpdate({
                    forecastId: id,
                    addressId: this.selectedRowKeys[0],
                }).then(() => {
                    res(true)
                }).catch(() => {
                    rej(false)
                })
            })

        },
        handleCancel() {
            this.model.visible = false;
        },
        reset() {
            this.form.keyword = "";
        },
        search() {
            this.form.page = 1;
            this.getData();
        },
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        //table行点击
        rowClick(record) {
            return {
                on: {
                    click: () => {
                        this.selectedRowKeys.length = 0;
                        this.selectedRowKeys.push(record.id);
                        if (!this.type) {
                            this.content = `${record.name} - (${record.address}) `;
                        } else if (this.type === "fba") {
                            this.content = `${record.country_name} - ${record.fba_name} - (${record.address}) `;
                        } else {
                            this.content = `${record.country_name} - ${record.name} - (${record.address}) `;
                        }
                    },
                },
            };
        },
    },
};
</script>