/*
 * @Author: your name
 * @Date: 2021-11-18 17:21:01
 * @LastEditTime: 2021-12-10 11:13:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/state.js
 */
const state = {
    token: sessionStorage.getItem('token') || "",
    selectedKeys: JSON.parse(sessionStorage.getItem('selectedKeys')) || ['/'],
    openKeys: JSON.parse(sessionStorage.getItem('openKeys')) || [],
    tagViews: JSON.parse(sessionStorage.getItem('tagViews')) || [],
    waybillIndex: sessionStorage.getItem('waybillIndex') || '2',
    addressIndex: sessionStorage.getItem('addressIndex') || '1',
    user:sessionStorage.getItem('user') || "未知用户",
    loginStep: 0
}
export default state