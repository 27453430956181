/*
 * @Author: your name
 * @Date: 2021-12-07 10:48:30
 * @LastEditTime: 2021-12-28 16:48:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/columns.js
 */
export default [
        {
            title: "商品标题",
            dataIndex: "zh_name",
            key: "zh_name",
            width: 100
        },
        {
            title: "用途中文名",
            dataIndex: "zh_purpose",
            key: "zh_purpose",
            width: 130
        },
        {
            title: "用途英文名",
            dataIndex: "en_purpose",
            key: "en_purpose",
            width: 130
        },
        {
            title: "材质中文名",
            dataIndex: "zh_material",
            key: "zh_material",
            width: 130
        },
        {
            title: "材质英文名",
            dataIndex: "en_material",
            key: "en_material",
            width: 130
        },
        {
            title: "海关编码",
            dataIndex: "hs_code",
            key: "hs_code",
            width: 100
        },
        {
            title: "重量kg",
            dataIndex: "weight",
            key: "weight",
            width: 100
        },
        {
            title: "亚马逊商品链接",
            dataIndex: "amazon_url",
            key: "amazon_url",
            width: 200
        },
        {
            title: "货币",
            dataIndex: "declareds",
            key: "declareds", 
            scopedSlots: { customRender: 'declareds' },
            width: 120
        },
        // {
        //     title: "材质",
        //     dataIndex: "material_cates",
        //     key: "material_cates",
        //     scopedSlots: { customRender: 'materialCates' }, 
        // },
        {
            title: "品名中文名",
            dataIndex: "item",
            key: "item",
            scopedSlots: { customRender: 'itemArr' }, 
            width: 120
        }
]
