/*
 * @Author: your name
 * @Date: 2021-12-01 15:54:41
 * @LastEditTime: 2021-12-06 13:56:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Waybill/index.js
 */

import httpService from "@/request";

// 获取预报列表
export function forecastList(params) {
  return httpService({
    url: `/order/forecast/lists`,
    method: "post",
    data: params,
  });
}

// 取消预报
export function forecastCancel(params) {
  return httpService({
    url: `/order/forecast/cancel`,
    method: "post",
    data: params,
  });
}
// 预报发货
export function forecastDelivery(params) {
  return httpService({
    url: `/order/forecast/delivery`,
    method: "post",
    data: params,
  });
}
// 预报详情
export function forecastInfo(params) {
  return httpService({
    url: `/order/forecast/info`,
    method: "post",
    data: params,
  });
}
// 获取发票列表
export function invoiceList(params) {
  return httpService({
    url: `/order/invoice/lists`,
    method: "post",
    data: params,
  });
}
//获取运单详情
export function waybillInfo(params) {
  return httpService({
    url: `/order/waybill/info`,
    method: "post",
    data: params,
  });
}
// 保存发票
export function saveInvoice(params) {
  return httpService({
    url: `/order/invoice/save`,
    method: "post",
    data: params,
  });
}
// 制作发票
export function makeInvoice(params) {
  return httpService({
    url: `/order/invoice/submit`,
    method: "post",
    data: params,
  });
}
// 发票草稿
export function invoiceDraft(params) {
  return httpService({
    url: `/order/invoice/draft`,
    method: "post",
    data: params,
  });
}
// 获取发票详情
export function invoiceInfo(params) {
  return httpService({
    url: `/order/invoice/info`,
    method: "post",
    data: params,
  });
}
// 获取下单列表
export function waybillCreateList(params) {
  return httpService({
    url: `/order/waybill/create`,
    method: "post",
    data: params,
  });
}
// 入仓
export function warehousingList(params) {
  return httpService({
    url: `/order/waybill/irikura`,
    method: "post",
    data: params,
  });
}
// 运输中
export function shipmentList(params) {
  return httpService({
    url: `/order/waybill/shipment`,
    method: "post",
    data: params,
  });
}
// 签收
export function finishList(params) {
  return httpService({
    url: `/order/waybill/finish`,
    method: "post",
    data: params,
  });
}
// 全部订单
export function waybillAll(params) {
  return httpService({
    url: `/order/waybill/all`,
    method: "post",
    data: params,
  });
}
// 取消保险
export function waybillUnsafe(params) {
  return httpService({
    url: `/order/waybill/unsafe`,
    method: "post",
    data: params,
  });
}
// 购买保险
export function waybillSafe(params) {
  return httpService({
    url: `/order/waybill/safe`,
    method: "post",
    data: params,
  });
}
// 货件添加商品
export function cargoItemAdd(params) {
  return httpService({
    url: `/order/waybill/cargo_item/add`,
    method: "post",
    data: params,
  });
}
// 装箱清单删除商品
export function cargoItemDel(params) {
  return httpService({
    url: `/order/waybill/cargo_item/del`,
    method: "post",
    data: params,
  });
}
// 装箱清单-修改商品数量
export function cargoItemEdit(params) {
  return httpService({
    url: `/order/waybill/cargo_item/edit`,
    method: "post",
    data: params,
  });
}
//入仓材积明细
///order/waybill/cargo
export function waybillCargo(params) {
  return httpService({
    url: `/order/waybill/cargo`,
    method: "post",
    data: params,
  });
}
//货件修改
export function forecastEdit(params) {
  return httpService({
    url: `/order/forecast/num/edit`,
    method: "post",
    data: params,
  });
}
//运单修改
export function waybillEdit(params) {
  return httpService({
    url: `/order/waybill/num/edit
        `,
    method: "post",
    data: params,
  });
}
//轨迹
export function waybillMilestone(params) {
  return httpService({
    url: `/order/waybill/milestone
        `,
    method: "post",
    data: params,
  });
}
//保单啊
export function safeInfo(params) {
  return httpService({
    url: `/order/waybill/safe/info
        `,
    method: "post",
    data: params,
  });
}
//修改货好时间
export function goodTimeUpDate(params) {
  return httpService({
    url: `/order/forecast/goodTime/update
        `,
    method: "post",
    data: params,
  });
}

export function addressUpdate(params) {
  return httpService({
    url: `/order/forecast/address/update
        `,
    method: "post",
    data: params,
  });
}
//{{customer}}/order/forecast/waybill/cancel
export function waybillCancel(params) {
  return httpService({
    url: `/order/forecast/waybill/cancel
        `,
    method: "post",
    data: params,
  });
}

export function getFba(params) {
  return httpService({
    url: `/order/waybill/get/fbaNo
        `,
    method: "post",
    data: params,
  });
}

// 历史装箱清单
export function historyCargoes(params) {
  return httpService({
    url: `/order/invoice/historyCargoes`,
    method: "post",
    data: params,
  });
}
///{{customer}}/order/invoice/edit
export function invoiceEdit(params) {
  return httpService({
    url: `/order/invoice/edit`,
    method: "post",
    data: params,
  });
}
// 修改订单备注
export function updateBillRemark(params) {
  return httpService({
    url: `/order/forecast/waybill/remark/update`,
    method: "post",
    data: params,
  });
}
// 获取运单附件
export function getWaybillAttachment(params = {}) {
  return httpService({
    url: `/order/attachment/get`,
    method: "post",
    data: params,
  });
}
// 附件上传
export function addWaybillAttachment(data = {}) {
  return httpService({
    url: `/order/attachment/upload`,
    method: "post",
    data: data,
  });
}
// 附件编辑
export function editWaybillAttachment(data = {}) {
  return httpService({
    url: `/order/attachment/edit`,
    method: "post",
    data: data,
  });
}
// 附件删除
export function deleteWaybillAttachment(data = {}) {
  return httpService({
    url: `/order/attachment/delete`,
    method: "post",
    data: data,
  });
}
// 获取子单号信息
export const getChildNoInfoApi = (data = {}) => {
  return httpService({
    url: `/order/waybill/waybill/box/router`,
    method: "post",
    data: data,
  });
};
