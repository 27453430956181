/*
 * @Author: your name
 * @Date: 2021-12-02 10:03:35
 * @LastEditTime: 2021-12-28 16:10:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/utils/rules.js
 */
export default function validate({ name, msg, trigger, type }) {
  const ruleArr1 = [
    {
      required: true,
      message: msg,
      trigger: trigger ? trigger : ["change", "blur"],
    },
  ];
  const ruleArr2 = [
    {
      required: true,
      type: type,
      message: msg,
      trigger: trigger ? trigger : ["change", "blur"],
    },
  ];
  let arr;
  if (type) {
    arr = ruleArr2;
  } else {
    arr = ruleArr1;
  }
  const rules = createRules(arr, name);
  return rules;
}
// 创建规则
function createRules(rules, name) {
  const arr = [...rules];
  if (name === "phone") {
    let obj = {
      validator: checkMobile,
      trigger: ["change", "blur"],
    };
    arr.push(obj);
  }
  if (name === "interTelephone") {
    let obj = {
      validator: checkInterTelephone,
      trigger: ["change", "blur"],
    };
    arr.push(obj);
  }
  if (name === "isInterTelephone") {
    let obj = {
      validator: (rules, value, callback) => {
        if (value === "" || value === undefined) {
          callback();
        } else {
          checkInterTelephone(rules, value, callback);
        }
      },
      trigger: ["change", "blur"],
    };
    arr.push(obj);
    arr.splice(0, 1);
  }
  if (name === "isNum") {
    let obj = {
      validator: (rules, value, callback) => {
        if (value === "" || value === undefined) {
          callback();
        } else {
          checkNum(rules, value, callback);
        }
      },
      trigger: ["change", "blur"],
    };
    arr.push(obj);
    arr.splice(0, 1);
  }
  if (name === "isMail") {
    let obj = {
      validator: (rules, value, callback) => {
        if (value === "" || value === undefined) {
          callback();
        } else {
          checkMail(rules, value, callback);
        }
      },
      trigger: ["change", "blur"],
    };
    arr.push(obj);
    arr.splice(0, 1);
  }
  return arr;
}
// 正则验证手机号是否合法
function checkMobile(rules, value, callback) {
  const regMobile =
    /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
  if (regMobile.test(value) == true) {
    callback();
  } else {
    callback(new Error("请输入正确的手机号"));
  }
}
// 国际手机号和电话的验证
function checkInterTelephone(rules, value, callback) {
  const t1 =
    /(?:\(?[0\\+]\d{2,3}\)?)[\s-]?(?:(?:\(0{1,3}\))?[0\d]{1,4})[\s-](?:[\d]{7,8}|[\d]{3,4}[\s-][\d]{3,4})/; // 国际电话
  const t2 =
    /(?:\(?[0\\+]?\d{1,3}\)?)[\s-]?(?:0|\d{1,4})[\s-]?(?:(?:13\d{9})|(?:\d{7,8}))/; // 国际手机号
  if (t1.test(value) || t2.test(value)) {
    callback();
  } else {
    callback(new Error("请输入正确的国际电话或手机号"));
  }
}
// 验证是否为数字
function checkNum(rules, value, callback) {
  if (!isNaN(value)) {
    callback();
  } else {
    callback(new Error("请输入数字"));
  }
}
// 验证邮箱
function checkMail(rules, value, callback) {
  const reg = new RegExp(
    "^[a-zA-Z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
  ); //正则表达式
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error("请输入正确的邮箱"));
  }
}
