/*
 * @Author: your name
 * @Date: 2021-12-01 18:18:51
 * @LastEditTime: 2021-12-09 10:55:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/comm/index.js
 */
import httpService from "@/request";
// 获取国家列表
export function countryList(params) {
  return httpService({
    url: `/country/lists`,
    method: "post",
    data: params,
  });
}
// 非fba地址
export function noFbaAddress(params) {
  return httpService({
    url: `/user/address/mail/lists`,
    method: "post",
    data: params,
  });
}
// fba地址
export function fbaAddress(params) {
  return httpService({
    url: `/user/address/mail/fba/lists`,
    method: "post",
    data: params,
  });
}
// 寄件地址
export function addressList(params) {
  return httpService({
    url: `/user/address/lists`,
    method: "post",
    data: params,
  });
}
// 渠道类型
export function channelType(params) {
  return httpService({
    url: `/channel/type`,
    method: "post",
    data: params,
  });
}
// 渠道列表
export function channelList(params) {
  return httpService({
    url: `/channel/lists`,
    method: "post",
    data: params,
  });
}
// 获取产品
export function productList(params) {
  return httpService({
    url: `/product/lists`,
    method: "post",
    data: params,
  });
}
// 获取中国的省市区信息
export function addressChina(params) {
  return httpService({
    url: `/region/china`,
    method: "post",
    data: params,
  });
}
// 获取用户地址的id
export function addressLists(params) {
  return httpService({
    url: `/user/address/lists`,
    method: "post",
    data: params,
  });
}

// 仓库地址
export function storehouseLists(params) {
  return httpService({
    url: `/storehouse/lists`,
    method: "post",
    data: params,
  });
}
// fba仓库地址
export function storehouseFbaLists(params) {
  return httpService({
    url: `/storehouse/fba/lists`,
    method: "post",
    data: params,
  });
}
// 获取材质的接口
export function materialList(params) {
  return httpService({
    url: `/product/material/cate`,
    method: "post",
    data: params,
  });
}
// 获取产品分类
export function productCategory(params) {
  return httpService({
    url: `/product/category/all`,
    method: "post",
    data: params,
  });
}
// 获取品名
export function productItems(params) {
  return httpService({
    url: `/product/item/lists`,
    method: "post",
    data: params,
  });
}
// 获取币种列
export function currencyList(params) {
  return httpService({
    url: `/currency`,
    method: "post",
    data: params,
  });
}
// fba仓库
export function storehouseFba(params) {
  return httpService({
    url: `/storehouse/fba/search`,
    method: "post",
    data: params,
  });
}
//渠道国家筛选
///channel/select/country
export function channelCountry(params) {
  return httpService({
    url: `/channel/select/country`,
    method: "post",
    data: params,
  });
}

// 搜索国家
export function fbaCountry(params) {
  return httpService({
    url: `/storehouse/fba/country`,
    method: "post",
    data: params,
  });
}
// 文件下载
export const downloadFileByUrlApi = (data = {}) => {
  return httpService({
    url: "/file/download/file",
    method: "post",
    data,
    responseType: "blob",
  });
};
