/*
 * @Author: your name
 * @Date: 2021-12-08 09:49:28
 * @LastEditTime: 2021-12-08 10:34:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/ConfigInfo.js
 */
export default {
    path: '/ConfigInfo',
    name: "ConfigInfo",
    title: "配置信息",
    component: resolve => require(['@/views/ConfigInfo'], resolve),
    meta: { title: '配置信息' },
    icon: "pic-left",
    children: [
        {
            path: '/ConfigInfo/Product',
            name: "Product",
            title: "产品库",
            component: resolve => require(['@/views/ConfigInfo/Product'], resolve),
            meta: { title: '产品库' },
        },
        {
            path: '/ConfigInfo/Address',
            name: "Address",
            title: "地址信息",
            component: resolve => require(['@/views/ConfigInfo/Address'], resolve),
            meta: { title: '地址信息' },
        }
    ]
}