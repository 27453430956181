<!--
 * @Author: your name
 * @Date: 2021-11-18 17:27:08
 * @LastEditTime: 2021-12-28 14:00:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Layout/index.vue
-->
<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <img
          :class="['m1', collapsed ? 'collapsed' : '']"
          src="../../images/ansu-logo.png"
        />
        <!-- <span
          class="s1"
          v-if="!collapsed"
          >安速货运</span
        > -->
      </div>
      <yMenu :csd="collapsed"></yMenu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0; height: 78px">
        <div class="l-header">
          <div class="l-h-d1">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
            <!-- <a-breadcrumb>
                            <a-breadcrumb-item v-for="(item,index) in tags" :key="index"><a class="abt" @click="handlerBread(item)">{{item.meta.title}}</a></a-breadcrumb-item>
                        </a-breadcrumb> -->
          </div>

          <a-popconfirm
            title="确定要退出嘛？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="logOut"
          >
            <div style="display: inline-block; margin-right: 15px">
              {{ username }}
            </div>
            <a-icon class="al-right" type="logout" />
          </a-popconfirm>
        </div>
        <div class="l-h-tab">
          <div
            :class="
              currentPath === item.path
                ? 'l-h-item l-h-item-active'
                : 'l-h-item'
            "
            v-for="(item, index) in getTagViews"
            :key="index"
            @mouseenter="enter(item)"
            @mouseleave="leave(item)"
          >
            <span @click="handlerPath(item)">{{ item.label }}</span>
            <a-icon
              class="h-t-c"
              type="close-circle"
              v-if="item.close"
              @click="handlerDelTag(item, index)"
            />
          </div>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '10px', background: '#fff', overflow: 'auto' }"
      >
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import yMenu from "./yMenu";
export default {
  components: {
    yMenu,
  },
  watch: {
    $route: {
      handler(val) {
        this.currentPath = val.path;
        let obj = {
          label: val.meta.title,
          path: val.path,
          fullPath: val.fullPath,
          close: false,
        };
        this.setTagViews(obj);
        this.tags = val.matched;
      },
      immediate: true,
    },
  },
  data() {
    return {
      collapsed: false,
      tags: [],
      currentPath: "",
      username: "未知用户",
    };
  },
  created() {
    this.username = this.$store.getters.getUser;
  },
  methods: {
    handlerBread(row) {
      let path = row.fullPath || "/";
      this.$router.push(path);
    },
    handlerPath(item) {
      this.$router.replace(item.fullPath);
    },
    enter(item) {
      item.close = true;
    },
    leave(item) {
      item.close = false;
    },
    handlerDelTag(item, index) {
      if (item.path === this.currentPath && index != 0) {
        let arr = [...this.getTagViews];
        let ele = arr[index - 1];
        this.$router.replace(ele.fullPath);
      }
      if (index != 0) {
        this.detTagViews(item);
      }
    },
    // 退出
    logOut() {
      this.setToken("");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  .m1 {
    width: 140px;
    transition: all 0.4s;
  }
  .collapsed {
    transform: translateX(40px);
  }
  .s1 {
    display: block;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 500;
  }
}
.l-header {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eff2f5;
  .l-h-d1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .al-right {
    font-size: 18px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.l-h-tab {
  display: flex;
  height: 36px;
  justify-content: flex-start;
  .l-h-item {
    position: relative;
    min-width: 100px;
    max-width: 160px;
    height: 100%;
    line-height: 36px;
    text-align: center;
    border-left: 1px solid #eff2f5;
    border-right: 1px solid #eff2f5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 24px;
    margin-left: -1px;
    color: #a3a3a3;
    font-size: 14px;
    cursor: pointer;
  }
  .h-t-c {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 12px;
    color: #fd0000;
  }
  .l-h-item-active {
    color: #ff8134;
  }
}
.abt {
  cursor: pointer;
}
</style>
