/*
 * @Author: your name
 * @Date: 2021-12-07 10:48:30
 * @LastEditTime: 2021-12-08 13:53:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/columns.js
 */
export default [
        {
            title: "中文名",
            dataIndex: "zh_name",
            key: "zh_name",
        },
        {
            title: "英文名",
            dataIndex: "en_name",
            key: "en_name",
        },  
   
]
