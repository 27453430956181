/*
 * @Author: your name
 * @Date: 2021-12-07 10:48:30
 * @LastEditTime: 2021-12-09 11:08:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/columns.js
 */
export default {
    a: [
        {
            title: "仓库名称",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "联系人",
            dataIndex: "principal_name",
            key: "principal_name",
        },
        {
            title: "联系方式",
            dataIndex: "principal_phone",
            key: "principal_phone",
        },
        {
            title: "仓库地址",
            dataIndex: "address",
            key: "address",
        }
    ],
    b: [
        {
            title: "国家",
            dataIndex: "country_name",
            width: 100,
            key: "country_name",
        },
        {
            title: "仓库名称",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "仓库地址",
            dataIndex: "address",
            key: "address",
        }
    ],
   
    
}
