/*
 * @Author: your name
 * @Date: 2021-11-22 15:34:24
 * @LastEditTime: 2021-12-10 11:20:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/mixins/index.js
 */
import { mapGetters, mapActions } from "vuex";
import validate from "@/utils/rules";
import XLSX from "xlsx";
import JsBarcode from "jsbarcode";
import QRCode from "qrcodejs2";
export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters([
          "getToken",
          "getSelectedKeys",
          "getOpenKeys",
          "getTagViews",
          "getWaybillIndex",
          "getAddressIndex",
          "getLoginStep",
          "getUser",
        ]),
      },
      directives: {
        barcode: {
          bind(el, binding) {
            let { no, width } = binding.value;
            let height = binding.value.height || 68;
            JsBarcode(el, no, {
              format: "CODE128",
              lineColor: "#000",
              background: "#fff",
              fontSize: 15,
              width: width,
              height: height,
              displayValue: true,
            });
          },
        },
        qrcode: {
          bind(el, binding) {
            const { no, width, height } = binding.value;
            new QRCode(el, {
              text: no, //二维码内容
              width, // 二维码的宽
              height, //二维码的高
              colorDark: "#333", //二维码的颜色
              colorLight: "#fff", //二维码的背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率, L/M/H
            });
          },
        },
      },
      methods: {
        validate,
        ...mapActions([
          "setToken",
          "setSelectedKeys",
          "setOpenKeys",
          "setTagViews",
          "detTagViews",
          "setWaybillIndex",
          "setAddressIndex",
          "setLoginStep",
          "setUser",
        ]),
        uuid() {
          function S4() {
            return (((1 + Math.random()) * 0x10000) | 0)
              .toString(16)
              .substring(1);
          }
          return (
            S4() +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            S4() +
            S4()
          );
        },
        impExcel(e) {
          //导入信息
          let fileName = e.target.files[0];
          return new Promise(function (resolve) {
            let reader = new FileReader();
            //启动函数
            reader.readAsBinaryString(fileName);
            reader.onload = function (e) {
              const workbook = XLSX.read(e.target.result, {
                type: "binary",
                sheetStubs: true,
              });
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const results = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
              });
              resolve(results);
            };
          });
        },
        sortArr(arr = [], prarm) {
          for (let i = 0; i < arr.length; i++) {
            for (let j = i + 1; j < arr.length; j++) {
              let a = arr[i];
              let b = arr[j];
              let k = arr[i];
              if (a[prarm] > b[prarm]) {
                arr[i] = b;
                arr[j] = k;
              }
            }
          }
          return arr;
        },
      },
    });
  },
};
