/*
 * @Author: your name
 * @Date: 2021-11-18 17:15:53
 * @LastEditTime: 2021-11-30 10:53:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import './styles/index.less';
import mixins from "@/mixins"
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import selfUi from "@/components/index.js";
import Grid from 'vue-canvas-grid'
Vue.use(Antd);
Vue.use(mixins);
Vue.use(selfUi);
import store from "@/store";
import router from "@/permission";
Vue.component('grid', Grid)
import htmlToPdf from '@/utils/htmlToPdf';
Vue.use(htmlToPdf);
Vue.config.productionTip = false
import *  as echarts from 'echarts'
Vue.prototype.$echarts = echarts
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
