/*
 * @Author: your name
 * @Date: 2021-12-07 10:48:30
 * @LastEditTime: 2021-12-07 11:56:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/columns.js
 */
export default {
    a: [
        {
            title: "姓名",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "手机号码",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "手机号码二",
            dataIndex: "phone_two",
            key: "phone_two",
        },
        {
            title: "地址",
            dataIndex: "address",
            key: "address",
        }
    ],
    b: [
        {
            title: "国家",
            dataIndex: "country_name",
            key: "country_name",
        },
        {
            title: "仓库",
            dataIndex: "fba_name",
            key: "fba_name",
        },
        {
            title: "地址",
            dataIndex: "address",
            key: "address",
        }
    ],
    c: [
        {
            title: "国家名称",
            dataIndex: "country_name",
            key: "country_name",
        },
        {
            title: "姓名",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "区号",
            dataIndex: "country_area_code",
            key: "country_area_code",
        },
        {
            title: "电话",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "邮箱",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "邮编",
            dataIndex: "zip_code",
            key: "zip_code",
        },
        {
            title: "地址",
            dataIndex: "address",
            key: "address",
        }
    ]
}
