/*
 * @Author: your name
 * @Date: 2021-12-08 18:47:37
 * @LastEditTime: 2021-12-09 15:26:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Address/index.js
 */
import httpService from "@/request"

// 添加地址
export function addressAdd(params) {
    return httpService({    
        url: `/user/address/add`,
        method: 'post',
        data: params,
    })
}

// 地址详情
export function addressInfo(params) {
    return httpService({    
        url: `/user/address/info`,
        method: 'post',
        data: params,
    })
}

// 编辑地址
export function addressEdit(params) {
    return httpService({    
        url: `/user/address/edit`,
        method: 'post',
        data: params,
    })
}
// 删除地址
export function addressDel(params) {
    return httpService({    
        url: `/user/address/del`,
        method: 'post',
        data: params,
    })
}
// 添加fba地址
export function addressFbaAdd(params) {
    return httpService({    
        url: `/user/address/mail/fba/add`,
        method: 'post',
        data: params,
    })
}
// 编辑fba地址
export function addressFbaEdit(params) {
    return httpService({    
        url: `/user/address/mail/fba/edit`,
        method: 'post',
        data: params,
    })
}
//删除fba地址   
export function addressFbaDel(params) {
    return httpService({    
        url: `/user/address/mail/fba/del`,
        method: 'post',
        data: params,
    })
}
// fba地址详情
export function storehouseFbaInfo(params) {
    return httpService({    
        url: `/storehouse/fba/info`,
        method: 'post',
        data: params,
    })
}

// 非fba地址删除
export function addressNoFbaDel(params) {
    return httpService({    
        url: `/user/address/mail/del`,
        method: 'post',
        data: params,
    })
}

// 非fba地址添加
export function addressNoFbaAdd(params) {
    return httpService({    
        url: `/user/address/mail/add`,
        method: 'post',
        data: params,
    })
}

// 非fba地址编辑
export function addressNoFbaEdit(params) {
    return httpService({    
        url: `/user/address/mail/edit`,
        method: 'post',
        data: params,
    })
}

// 非fba地址详情
export function addressNoFbaInfo(params) {
    return httpService({    
        url: `/user/address/mail/info`,
        method: 'post',
        data: params,
    })
}
//
///{{customer}}/region/china/cascade
export function chinaCascade(params) {
    return httpService({    
        url: `/region/china/cascade`,
        method: 'post',
        data: params,
    })
}