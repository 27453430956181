<!--
 * @Author: your name
 * @Date: 2021-12-07 12:06:12
 * @LastEditTime: 2022-01-07 09:48:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseTickets/index.vue
-->
<template>
    <div>
        <div class="tk-container">
            <span>{{content}}</span>
            <a class="btn-a" @click="openDrawer">添加票数据</a>
        </div>
        <a-drawer :title="drawer.title" placement="right" :maskClosable="false" :visible="drawer.visible" @close="handlerCancel" :width="drawer.width">
            <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="drawer.visible">
                <template v-for="(item,index) in form.tickets">
                    <a-row :key="index">
                        <a-form-model-item label="是否FBA" prop="type">
                            <a-row>
                                <a-col :span="7">
                                    <a-radio-group v-model="item.type" @change="handlerFba(item)">
                                        <a-radio :value="2">
                                            非FBA
                                        </a-radio>
                                        <a-radio :value="1">
                                            FBA
                                        </a-radio>
                                    </a-radio-group>
                                </a-col>
                                <a-col :span="4">
                                    <a-button v-if="index === form.tickets.length - 1" type="primary" icon="plus" @click="addTickets(form.tickets)">添加票据</a-button>
                                    <a-button v-else type="primary" icon="minus" @click="reduceTickets(form.tickets, index)" />
                                </a-col>
                            </a-row>

                        </a-form-model-item>
                        <a-form-model-item label="目的地" :prop="`tickets.`+index+'.addressId'" :rules="validate({name: 'value', msg: '请选择目的地'})">
                            <chooseAdress v-model="item.addressId" :type="item.addressType" @change="handlerAdress(item)"></chooseAdress>
                        </a-form-model-item>
                        <a-form-model-item label="渠道类型" v-if="item.addressId">
                            <a-radio-group v-model="item.cate" @change="handlerCate(item)">
                                <a-radio :value="it.id" v-for="it in channelType" :key="it.id">
                                    {{it.name}}
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item label="渠道" :prop="`tickets.`+index+'.channelId'" :rules="validate({name: 'value',msg: '请选择渠道'})" v-if="item.addressId">
                            <a-select v-model="item.channelId" style="width: 200px" allowClear placeholder="渠道">
                                <a-select-option :value="it.id" v-for="it in channelList" :key="it.id">
                                    {{it.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="是否投保" prop="haveSafe">
                            <a-radio-group v-model="item.haveSafe">
                                <a-radio :value="0">
                                    否
                                </a-radio>
                                <a-radio :value="1">
                                    是
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item label="材质分类" :prop="`tickets.`+index+'.materialCates'" :rules="validate({name: 'value', type: 'array', msg: '材质不能为空'})">
                            <a-checkbox-group v-model="item.materialCates" :options="materialCates" />
                        </a-form-model-item>
                        <a-form-model-item label="备注" prop="remark">
                            <a-input v-model="item.remark" type="textarea" style="width: 300px" />
                        </a-form-model-item>
                        <a-form-model-item v-if="item.type === 1" label="货件编号" :prop="`tickets.`+index+'.shipNo'" :rules="validate({name: 'value',msg: '货件编号不能为空'})">
                            <a-input v-model="item.shipNo" style="width: 300px" />
                        </a-form-model-item>
                        <a-form-model-item label="货件数量" :prop="`tickets.`+index+'.shipNum'" :rules="validate({name: 'value',msg: '货件数量不能为空'})">
                            <a-input-number v-model="item.shipNum" :min="1" :max="9999" style="width: 300px" @change="handlerShipNum(item,item.checklists)" />
                        </a-form-model-item>
                        <a-form-model-item label="货件列表" :prop="`tickets.`+index+'.checklists'">
                            <div :style="x === 0 ? '': 'margin-left: 110px'" v-for="(iit,x) in item.checklists" :key="x">
                                <span>{{iit.no}}</span>
                                <!-- <a-button type="link" @click="doChecklist(iit)">配置装箱清单</a-button> -->
                            </div>
                        </a-form-model-item>
                        <!-- <a-form-model-item label="货件列表" :prop="`tickets.`+index+'.checklists'">
                            <a-row v-for="(it,x) in item.checklists" :key="x">
                                <a-row :style="x === 0 ? '' : 'margin-left: 110px'">
                                    <a-col :span="8" style="margin-right: 5px">
                                        <a-form-model-item :prop="`tickets[${index}].checklists[${x}].no`" :rules="validate({name: 'value', msg: '货物编号不能为空'})">
                                            <a-input v-model="it.no" allowClear placeholder="货物编号" style="width: 200px" />
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="4">
                                        <a-button v-if="x === item.checklists.length - 1" type="primary" icon="plus" @click="addChecklists(item.checklists)" >添加货件</a-button>
                                        <a-button v-else type="primary" icon="minus" @click="reduceChecklists(item.checklists, x)" />
                                    </a-col>
                                </a-row>
                                <a-row style="margin-left: 110px" v-for="(iit,n) in it.products" :key="'p'+n">
                                    <a-col :span="9" style="margin-right: 5px">
                                        <a-form-model-item :label-col="labelCol" :prop="`tickets[${index}].checklists[${x}].products[${n}].productId`" :rules="validate({name: 'value', msg: '产品id不能为空'})">
                                            <chooseProduct v-model="iit.productId"></chooseProduct>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="7" style="margin-right: 5px">
                                        <a-form-model-item :prop="`tickets[${index}].checklists[${x}].products[${n}].productCount`" :rules="validate({name: 'value', msg: '产品数量不能为空'})">
                                            <a-input-number style="width: 100%" allowClear v-model="iit.productCount" placeholder="产品数量" />
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="4">
                                        <a-button v-if="n === it.products.length - 1" type="primary" icon="plus" @click="add(it.products)">添加产品</a-button>
                                        <a-button v-else type="primary" icon="minus" @click="reduce(it.products, n)" />
                                    </a-col>
                                </a-row>
                            </a-row>
                        </a-form-model-item> -->
                    </a-row>
                </template>
            </a-form-model>
            <div class="drawerFooter">
                <a-button-group>
                    <a-button @click="handlerCancel">
                        取消
                    </a-button>
                    <a-button type="primary" @click="handlerSubmit">
                        确定
                    </a-button>
                </a-button-group>
            </div>
            <a-drawer :title="md.title" :visible="md.visible" :width="md.width" @close="handlerSubmit2" :maskClosable="false">
                <div v-if="md.visible">
                    <a-button type="primary" icon="plus" @click="add(checklistItem.products)">添加产品</a-button>
                    <a-row style="margin-top: 15px" v-for="(iit,n) in checklistItem.products" :key="n">
                        <a-col :span="9" style="margin-right: 5px">
                            <chooseProduct v-model="iit.productId"></chooseProduct>
                        </a-col>
                        <a-col :span="7" style="margin-right: 5px">
                            <a-input-number style="width: 100%" allowClear v-model="iit.productCount" placeholder="产品数量" />
                        </a-col>
                        <a-col :span="4">
                            <a-button type="primary" icon="minus" @click="reduce(checklistItem.products, n)" />
                        </a-col>
                    </a-row>
                </div>
                <div class="drawerFooter">
                    <a-button-group>
                        <a-button @click="handlerSubmit2">
                            取消
                        </a-button>
                        <a-button type="primary" @click="handlerSubmit2">
                            确定
                        </a-button>
                    </a-button-group>
                </div>
            </a-drawer>
        </a-drawer>
    </div>
</template>

<script>
import { channelList, channelType, materialList } from "@/api/comm";
export default {
    name: "chooseTickets",
    props: {
        tickets: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        tickets: {
            handler(val) {
                this.content = `${val.length}条 `;
                if (val.length > 0) {
                    this.form.tickets = [...val];
                }
            },
            immediate: true,
        },
    },
    model: {
        prop: "tickets",
        event: "returnTickets",
    },
    data() {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            channelList: [],
            content: "",
            drawer: {
                title: "选择票数据",
                visible: false,
                width: "700px",
            },
            materialCates: [],
            md: {
                title: "",
                visible: false,
                width: "460px",
            },
            checklistItem: {},
            form: {
                tickets: [
                    {
                        type: 1,
                        cate: 1,
                        addressType: 'fba',
                        addressId: "",
                        channelId: "",
                        haveSafe: 0,
                        materialCates: [],
                        remark: "",
                        shipNum: "",
                        shipNo: "",
                        checklists: [
                            // {
                            //     no: "",
                            //     products: [
                            //         {
                            //             productId: "",
                            //             productCount: null,
                            //         },
                            //     ],
                            // },
                        ],
                    },
                ],
            },
        };
    },
    created() {
        this.getChannelType();
        this.getMaterial();
    },
    methods: {
        openDrawer() {
            this.drawer.visible = true;
        },
        handlerCancel() {
            this.drawer.visible = false;
        },
        handlerSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$emit("returnTickets", this.form.tickets);
                    this.$emit("change", this.form.tickets);
                    this.drawer.visible = false;
                } else {
                    this.$emit("fail");
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        // 获取材质的接口
        getMaterial() {
            materialList().then((res) => {
                let list = res.data;
                const arr = [];
                list.forEach((ele) => {
                    let obj = {
                        label: ele.name,
                        value: ele.id,
                    };
                    arr.push(obj);
                });
                this.materialCates = arr;
            });
        },
        // 改变fba
        handlerFba(row) {
            row.addressId = "";
            row.shipNum = undefined;
            row.checklists = [];
            if (row.type === 1) {
                row.addressType = "fba";
            } else {
                row.addressType = 1;
            }
            
        },
        // 改变收获地址
        handlerAdress(row) {
            row.channelId = "";
            this.getChannel(row);
        },
        // 改变渠道类型
        handlerCate(row) {
            row.channelId = "";
            this.getChannel(row);
        },
        // 获取渠道类型
        getChannelType() {
            channelType().then((res) => {
                this.channelType = res.data;
            });
        },
        // 获取渠道
        getChannel(row) {
            let obj = {
                cate: row.cate,
                type: row.type,
                addressId: row.addressId * 1,
            };
            channelList(obj).then((res) => {
                this.channelList = res.data.list;
            });
        },
        // 增加货物
        add(arr) {
            let obj = {
                productId: "",
                productCount: null,
            };
            arr.push(obj);
        },
        // 减少
        reduce(arr, n) {
            arr.splice(n, 1);
        },
        addChecklists(arr) {
            let obj = {
                no: "",
                products: [
                    {
                        productId: "",
                        productCount: null,
                    },
                ],
            };
            arr.push(obj);
        },
        reduceChecklists(arr, n) {
            arr.splice(n, 1);
        },
        // 添加一票
        addTickets(arr) {
            let obj = {
                type: 1,
                cate: 1,
                addressType: 'fba',
                addressId: "",
                channelId: "",
                haveSafe: 0,
                magneticType: 0,
                remark: "",
                checklists: [],
            };
            arr.push(obj);
        },
        reduceTickets(arr, n) {
            arr.splice(n, 1);
        },
        // 修改货件数量
        handlerShipNum(row) {
            let no = "";
            if (row.type === 2) {
                no = "箱子";
            } else if (row.type === 1) {
                no = row.shipNo;
            }
            let n = row.shipNum;
            if (!no && row.type === 1) {
                this.$message.error("请输入货件编号");
                row.shipNum = "";
                return;
            }
            const list = [];
            for (let i = 1; i < n + 1; i++) {
                let obj = {
                    no: no + "" + this.noStr(i, 6),
                    products: [],
                };
                list.push(obj);
            }
            row.checklists = list;
        },
        // 位数处理
        noStr(num, n) {
            let str = num + "";
            let s1 = "";
            let m = n - str.length;
            if (m > 0) {
                for (let i = 0; i < m; i++) {
                    s1 += "0";
                }
            }
            s1 += str;
            return s1;
        },
        // 配置装箱清单
        doChecklist(row) {
            this.md.title = "配置装箱清单";
            this.checklistItem = row;
            this.md.visible = true;
        },
        // 添加产品的确定
        handlerSubmit2() {
            const filerArr = this.checklistItem.products.filter(ele => {
                return ele.productId && ele.productCount
            })
            this.checklistItem.products = filerArr;
            this.md.visible = false;
        }
    },
};
</script>

<style lang="less" scoped>
</style>
