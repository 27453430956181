/*
 * @Author: your name
 * @Date: 2021-12-08 10:28:02
 * @LastEditTime: 2021-12-08 10:29:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/MySettings.js
 */
export default {
    path: '/mySettings',
    name: "MySettings",
    title: "我的设置",
    hide: false,
    icon: 'setting',
    component: resolve => require(['@/views/MySettings'], resolve),
    meta: { title: '我的设置' }
}