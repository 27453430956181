/*
 * @Author: your name
 * @Date: 2021-12-08 10:00:14
 * @LastEditTime: 2021-12-08 10:01:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/Waybill.js
 */
  export default {
    path: '/ReturnWaybill',
    name: "ReturnWaybill",
    title: "退货单",
    component: resolve => require(['@/views/ReturnWaybill'], resolve),
    meta: { title: '退货单' },
    icon: "pic-left",
    children: [
        {
            path: '/ReturnWaybill/ReturnBill',
            name: "ReturnBill",
            title: "退货运单",
            component: resolve => require(['@/views/ReturnWaybill/ReturnBill'], resolve),
            meta: { title: '退货运单' },
        },
        {
            path: '/ReturnWaybill/RemakeBill',
            name: "RemakeBill",
            title: "重发运单",
            component: resolve => require(['@/views/ReturnWaybill/RemakeBill'], resolve),
            meta: { title: '重发运单' },
        },
        {
            path: '/ReturnWaybill/ReturnOrder',
            name: "ReturnOrder",
            title: "退件单",
            component: resolve => require(['@/views/ReturnWaybill/ReturnOrder'], resolve),
            meta: { title: '退件单' },
        },
        {
            path: '/ReturnWaybill/ReturnOrder/com/editDetail',
            name: "editDetail",
            hide: true,
            component: resolve => require(['@/views/ReturnWaybill/ReturnOrder/com/editDetail'], resolve),
            meta: { title: '退件单详情' },
        }
    ]
}