<template>
  <div class="et-container">
    <div class="et-top" align="right">
      <a-button-group>
        <!-- <a-button style="margin-left: 10px" type="primary" @click="downloadExcel">导出excel</a-button> -->
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" @click="submit">确定</a-button>
      </a-button-group>
    </div>
    <div id="luckysheet"></div>
    <div v-show="isMaskShow" id="tip">Downloading</div>
  </div>
</template>

<script>
import { exportExcel } from "./export";
import FileSaver from "file-saver";
import _ from "lodash";

export default {
  props: {
    sheelHeader: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sheelData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columnlen: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sheetName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isMaskShow: false,
      celldata: [],
    };
  },
  mounted() {
    // window.luckysheet.create({
    //   container: 'luckysheet',
    //   lang: 'zh',

    // })
    this.init();
    this.loadExcel();
  },
  methods: {
    // 加载文件
    loadExcel() {
      window.luckysheet.destroy();
      window.luckysheet.create({
        container: "luckysheet", //luckysheet is the container id
        lang: "zh",
        showinfobar: false,
        title: "在线编辑",
        userInfo: "Lucky",
        row: 100,
        data: [
          {
            defaultColWidth: 100,
            defaultRowHeight: 22,
            name: this.sheetName,
            status: 1,
            celldata: this.celldata,
            frozen: {
              type: "row",
            },
            config: {
              columnlen: this.columnlen,
            },
          },
        ],
        hook: {
          cellUpdateBefore(val) {
            if (!val) {
              return false;
            }
          },
          cellUpdated: (r, c, oldVal, newVal) => {
            if (r == 0 || c != 0) return false;
            if (newVal.m.length == 19) {
              let value = newVal.m;
              let cutValue = value.slice(0, value.length - 6);
              let cuttedValue = value.slice(value.length - 6, value.length) * 1;
              // newVal.f = `="${cutValue}"&TEXT(${cuttedValue}+ROW(A1)-1,"000000")`;
              // newVal.ct = { fa: "@", t: "s" };
              // newVal.qp = 1;
              window.luckysheet.setCellFormat(
                r,
                c,
                "f",
                `="${cutValue}"&TEXT(${cuttedValue}+ROW(A1)-1,"000000")`
              );
              window.luckysheet.setCellFormat(r, c, "ct", { fa: "@", t: "s" });
              window.luckysheet.setCellFormat(r, c, "qp", 1);
            }
          },
          cellMousedownBefore(val, position) {
            if (!position.r) {
              return false;
            }
          },
          setFormula(val) {
            console.log(val);
          },
        },
      });
    },
    // 初始化数据
    init() {
      let arr = _.cloneDeep(this.sheelHeader);
      let r = 1;
      this.sheelData.forEach((ele) => {
        this.sheelHeader.forEach((e) => {
          let obj = {
            r: r,
            c: e.c,
            v: {
              fs: 9,
              m: ele[e.prop],
              v: ele[e.prop],
              qp: 1,
              ct: {
                fa: "General",
                t: "g",
              },
            },
          };
          if (e.prop === "fbaNo") {
            obj.v.ct.fa = "@";
            obj.v.ct.t = "s";
            if (obj.v.m.length > 6) {
              let value = obj.v.m;
              let cutValue = value.slice(0, value.length - 6);
              let cuttedValue = value.slice(value.length - 6, value.length) * 1;
              obj.v.f = `="${cutValue}"&TEXT(${cuttedValue}+ROW(A1)-1,"000000")`;
            }
          } else if (e.prop === "declaredValue") {
            obj.v.ct.fa = "0.00";
            obj.v.ct.t = "n";
          } else if (e.prop === "num") {
            obj.v.ct.fa = "0";
            obj.v.ct.t = "n";
          }
          arr.push(obj);
        });
        r++;
      });
      this.celldata = arr;
    },
    // 确定
    submit() {
      let cdata = window.luckysheet.getAllSheets()[0].celldata;
      let cArr = cdata.splice(this.sheelHeader.length);
      let newObj = {};
      let tableData = [];
      cArr.forEach((ele) => {
        if (newObj[ele.r]) {
          newObj[ele.r].push(ele);
        } else {
          newObj[ele.r] = [ele];
        }
      });
      for (let i in newObj) {
        if (i === 0) {
          continue;
        }
        let fArr = newObj[i];
        let obj = {};
        fArr.forEach((e) => {
          let row = this.sheelHeader.find((x) => x.c == e.c);
          if (row) {
            obj[row.prop] = e.v.v || "";
          }
        });
        if (obj.fbaNo) {
          tableData.push(obj);
        }
      }
      window.luckysheet.exitEditMode();
      this.$emit("excelData", tableData);
    },
    // 下载文件
    downloadExcel() {
      exportExcel(window.luckysheet.getAllSheets()).then((res) => {
        // console.log(window.luckysheet.getAllSheets())
        FileSaver.saveAs(res, `${this.fileName}.xlsx`);
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="less" scoped>
.et-top {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
}

.et-container {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 88%;
  height: 100%;
  min-height: 680px;
  box-shadow: 0px 0px 100px 10px black;
  z-index: 1000;
}

#luckysheet {
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 30px;
  bottom: 0px;
}

#uploadBtn {
  font-size: 16px;
}

#tip {
  position: absolute;
  z-index: 1000000;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

/deep/ .luckysheet-share-logo {
  display: none;
}

/deep/ .luckysheet_info_detail {
  height: 0px;
  overflow: hidden;
}
</style>
