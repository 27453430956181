<template>
    <div style="padding-bottom: 20px" v-if="refresh">
        <grid ref="gridSource" :grid-data="gridData" :left-height="160" :columns="columns" columnSet
            @updateValue="updateDataFun"></grid>
        <div style="margin-top: 10px" align="right">
            <a-pagination :default-current="page" :total="pageTotal" @change="handlerChange" />
        </div>
    </div>
</template>

<script>
import { uuid } from "@/utils/plugs"
export default {
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        tableData: {
            type: Array,
            default: () => []
        },
        ge: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        //    ge: {
        //      handler(val) {
        //         this.gridExcelData = val || {};
        //      },
        //      immediate: true
        //    },
        tableData: {
            handler(val) {
                //格式化数据
                let obj = {}
                for (let i = 1; i <= Math.ceil(val.length / 1000); i++) {
                    obj[i] = val.slice((i - 1) * 1000, i * 1000)
                }
                this.gridExcelData = obj
            },
            immediate: true
        }
    },
    data() {
        return {
            gridData: [],
            prarms: {},
            page: 1,
            pageTotal: 3500,
            gridExcelData: {},
            refresh: true
        }
    },
    created() {
        this.createPrarms();
        this.initData(this.tableData);
    },
    methods: {
        updateDataFun(val = []) {
            const ids = [];
            const map = new Map()
            val.forEach(ele => {
                ids.push(ele.rowData.id);
                let str = ele.rowData.id
                map.set(str, ele.rowData)
            })
            for (let i = 0; i < this.gridData.length; i++) {
                if (ids.includes(this.gridData[i].id)) {
                    this.gridData[i] = map.get(this.gridData[i].id);
                }
            }
            this.gridExcelData[this.page] = this.gridData;
            // const arr = [];
            // for (let key in ldata) {
            //     arr.push([...ldata[key]])
            // }

        },
        setCellItem(rowIndex, cellIndex, val) {
            this.$refs.gridSource.setCellItem(rowIndex, cellIndex, val);
        },
        createPrarms() {
            let obj = {}
            this.columns.forEach(element => {
                if (element.type === 'number') {
                    obj[element.key] = null;
                } else {
                    obj[element.key] = '';
                }
            });
            this.prarms = obj
        },
        initData(ary = []) {
            const arr = [];
            for (let i = 0; i < 1000; i += 1) {
                if (i < ary.length) {
                    arr.push(ary[i])
                } else {
                    let obj = Object.assign({}, this.prarms);
                    obj.id = uuid();
                    arr.push(obj);
                }
            }
            this.gridData = arr;
            this.gridExcelData[this.page] = this.gridData;

        },
        createData(ary = []) {
            const arr = [];
            for (let i = 0; i < 1000; i += 1) {
                if (i < ary.length) {
                    arr.push(ary[i])
                } else {
                    let obj = Object.assign({}, this.prarms);
                    obj.id = uuid();
                    arr.push(obj);
                }
            }
            this.gridData = arr;
        },
        handlerChange(val) {
            this.page = val;
            this.refresh = false;
            const arr = this.gridExcelData[val] || []
            this.createData(arr);
            // this.createData(this.tableData)
            setTimeout(() => {
                this.refresh = true;
            }, 300)
        }
    }
}
</script>

<style lang="less" scoped>

</style>