/*
 * @Author: your name
 * @Date: 2021-12-08 10:00:14
 * @LastEditTime: 2021-12-08 10:01:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/Waybill.js
 */
  export default [
    {
        path: '/ForecastManagement',
        name: "ForecastManagement",
        title: "预报管理",
        icon: 'database',
        hide: false,
        component: resolve => require(['@/views/ForecastManagement'], resolve),
        meta: { title: '预报管理' },
    },
  ]