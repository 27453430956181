/*
 * @Author: your name
 * @Date: 2021-12-08 10:00:14
 * @LastEditTime: 2021-12-08 10:01:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/Waybill.js
 */
  export default [
    {
        path: '/waybill',
        name: "Waybill",
        title: "运单管理",
        icon: 'database',
        hide: false,
        component: resolve => require(['@/views/Waybill'], resolve),
        meta: { title: '运单管理' },
    },
    {
        path: '/Waybill/Forecast/_details',
        name: "Waybill_Forecast_details",
        title: "预报详情",
        hide: true,
        component: resolve => require(['@/views/Waybill/Forecast/_details'], resolve),
        meta: { title: '预报详情' },
    },
    {
        path: '/Waybill/ForecastCancelled/_details',
        name: "Waybill_ForecastCancelled_details",
        title: "预报详情",
        hide: true,
        component: resolve => require(['@/views/Waybill/ForecastCancelled/_details'], resolve),
        meta: { title: '预报详情' },
    },
    {
        path: '/Waybill/_details',
        name: "Waybill_details",
        title: "运单详情",
        hide: true,
        component: resolve => require(['@/views/Waybill/components/waybillDetails'], resolve),
        meta: { title: '运单详情' },
    },
    {
        path: '/Waybill/makeAnInvoice',
        name: "makeAnInvoice",
        title: "制作发票",
        hide: true,
        component: resolve => require(['@/views/Waybill/components/makeAnInvoice'], resolve),
        meta: { title: '制作发票' },
    }
]