<!--
 * @Author: your name
 * @Date: 2021-11-30 10:48:15
 * @LastEditTime: 2021-11-30 11:00:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/TabHeader/index.vue
-->
<template>
    <div class="th-container">
        <span class="th-title">{{title}}</span>
    </div>
</template>

<script>
export default {
    name: "TabHeader",
    props: {
        title: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="less" scoped>
.th-container {
    display: flex;
    height: 56px;
    padding: 0px 16px 0px 32px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
}
.th-title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}
</style>
