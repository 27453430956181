/*
 * @Author: your name
 * @Date: 2021-12-08 10:30:03
 * @LastEditTime: 2021-12-13 14:03:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/Billing.js
 */
export default {
    path: '/Billing',
    name: "Billing",
    title: "账单管理",
    icon: 'profile',
    hide: false,
    component: resolve => require(['@/views/Billing'], resolve),
    meta: { title: '账单管理' },
    children: [
        {
            path: '/Billing/WaybillFee',
            name: "WaybillFee",
            title: "运单费用",
            component: resolve => require(['@/views/Billing/WaybillFee'], resolve),
            meta: { title: '运单费用' },
        },
        {
            path: '/Billing/BillingFee',
            name: "BillingFee",
            title: "账单费用",
            component: resolve => require(['@/views/Billing/BillingFee'], resolve),
            meta: { title: '账单费用' },
        },
        {
            path: '/Billing/BillingFee/BillingFeeDetails',
            name: "BillingFeeDetails",
            title: "账单详情",
            hide: true,
            component: resolve => require(['@/views/Billing/BillingFee/BillingFeeDetails'], resolve),
            meta: { title: '账单详情' },
        },
    ]
}