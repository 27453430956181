/*
 * @Author: your name
 * @Date: 2021-12-08 14:46:28
 * @LastEditTime: 2021-12-08 17:44:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Product/index.js
 */
import httpService from "@/request"

// 添加产品
export function productAdd(params) {
    return httpService({    
        url: `/product/add`,
        method: 'post',
        data: params,
    })
}
// 获取产品详情
export function productInfo(params) {
    return httpService({    
        url: `/product/info`,
        method: 'post',
        data: params,
    })
}

// 编辑产品
export function productEdit(params) {
    return httpService({    
        url: `/product/edit`,
        method: 'post',
        data: params,
    })
}

// 删除产品
export function productDel(params) {
    return httpService({    
        url: `/product/del`,
        method: 'post',
        data: params,
    })
}
// 添加分类
export function pcAdd(params) {
    return httpService({    
        url: `/product/category/add`,
        method: 'post',
        data: params,
    })
}
// 删除分类
export function pcDel(params) {
    return httpService({    
        url: `/product/category/del`,
        method: 'post',
        data: params,
    })
}

//编辑分类
export function pcEdit(params) {
    return httpService({    
        url: `
product/category/edit`,
        method: 'post',
        data: params,
    })
}
