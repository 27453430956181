<!--
 * @Author: your name
 * @Date: 2021-12-20 13:57:59
 * @LastEditTime: 2022-02-11 11:43:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /shama-systems/src/components/lyDrawer/index.vue
-->
<template>
    <a-drawer class="cdd" :title="title" :placement="placement" :width="width" :visible="visible" @close="onClose"
        :maskClosable="false">
        <div :style="{'padding': '30px',background:background}">
            <slot></slot>
        </div>
        <div class="drawerFooter" align="right">
            <slot name="footer" v-if="$slots['footer']"></slot>
            <a-button-group v-else>
                <a-button @click="onClose">
                    取消
                </a-button>
                <a-button :loading="subLoading" type="primary" @click="handlerSubmit">
                    确定
                </a-button>
            </a-button-group>
        </div>
    </a-drawer>
</template>

<script>
    export default {
        name: "lyDrawer",
        props: {
            title: {
                type: String,
                default: "",
            },
            placement: {
                type: String,
                default: "right",
            },
            visible: {
                type: Boolean,
                default: false,
            },
            width: {
                type: [String, Number],
                default: '500px'
            },
            subLoading: {
                type: Boolean,
                default: false
            },
            background: {
                type: String,
                default:'white'
            }
        },
        model: {
            prop: "visible",
            event: "returnVisible",
        },
        // watch: {
        //     background: {
        //         handler(val) {
        //             this.$nextTick(() => {
        //                 console.log(val);
        //                 let ant_body = document.querySelector(".ant-drawer-body")
        //                 if (!ant_body) return
        //                 ant_body.style.background = val
        //             })

        //         },
        //         immediate:true
        //     }
        // },
        data() {
            return {

            }
        },
        methods: {
            // 关闭弹出框
            onClose() {
                this.$emit("cancel");
            },
            // 确定按钮
            handlerSubmit() {
                this.$emit("ok");
            }
        },
    };
</script>

<style lang="less" scoped>
    .cdd /deep/ .ant-drawer-body {
        padding: 0px;
    }
    .drawerFooter {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        text-align: right;
        z-index: 1;
    }
</style>