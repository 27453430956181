/*
 * @Author: your name
 * @Date: 2021-11-18 17:22:21
 * @LastEditTime: 2021-12-10 11:14:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/actions.js
 */
const actions = {
    setToken: ({commit}, log) => {
        commit('setToken', log)
    },
    setUser: ({commit}, log) => {
        commit('setUser', log)
    },
    setSelectedKeys: ({commit}, log) => {
        commit('setSelectedKeys', log)
    },
    setOpenKeys: ({commit}, log) => {
        commit('setOpenKeys', log)
    },
    setTagViews: ({commit}, log) => {
        commit('setTagViews', log)
    },
    detTagViews: ({commit}, log) => {
        commit('detTagViews', log)
    },
    setWaybillIndex: ({commit}, log) => {
        commit('setWaybillIndex', log)
    },
    setAddressIndex:  ({commit}, log) => {
        commit('setAddressIndex', log)
    },
    setLoginStep: ({commit}, log) => {
        commit('setLoginStep', log)
    },
}

export default actions