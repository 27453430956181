/*
 * @Author: your name
 * @Date: 2021-12-08 10:30:03
 * @LastEditTime: 2021-12-13 14:03:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/router/Billing.js
 */
export default {
    path: '/FeedBack',
    name: "FeedBack",
    title: "意见与反馈",
    icon: 'profile',
    hide: false,
    component: resolve => require(['@/views/FeedBack'], resolve),
    meta: { title: '意见与反馈' },
    children: [
        {
            path: '/FeedBack/InitiateFeedback',
            name: "InitiateFeedback",
            title: "发起反馈",
            component: resolve => require(['@/views/FeedBack/InitiateFeedback'], resolve),
            meta: { title: '发起反馈' },
        },
        {
            path: '/FeedBack/FeedbackHistory',
            name: "FeedbackHistory",
            title: "历史反馈",
            component: resolve => require(['@/views/FeedBack/FeedbackHistory'], resolve),
            meta: { title: '历史反馈' },
        },
    ]
}