/*
 * @Author: your name
 * @Date: 2021-11-22 11:18:57
 * @LastEditTime: 2021-11-22 15:04:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Layout/ost.js
 */

export default [
    {
        key: '1',
        title: '信息管理',
        path: '/info',
    },
    {
        key: '2',
        title: '用户管理',
        path: '/user',
        children: [
            { 
                key: '2.1',
                title: '后台用户',
                path: '/adminUser',
                children: [
                    { 
                        key: '2.1.1',
                        title: '新增用户',
                        path: '/addAdminUser',
                        children: [
                            {
                                key: '2.1.1。1',
                                title: '用户xx',
                                path: '/addAdminUserXX',
                            }
                        ]
                    }
                ]
            },
            { 
                key: '2.2',
                title: '前台用户',
                path: '/frontUser',
            }
        ]
    },
]
