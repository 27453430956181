/*
 * @Author: your name
 * @Date: 2021-11-18 17:21:16
 * @LastEditTime: 2021-12-10 11:14:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/store/mutations.js
 */
const mutations = {
    setToken: (state, log) => {
        state.token = log;
        sessionStorage.setItem('token', log);
    },
    setUser: (state, log) => {
        state.user = log
        sessionStorage.setItem('user', log);
    },
    setSelectedKeys: (state, log) => {
        state.selectedKeys = log;
        let str = JSON.stringify(log);
        sessionStorage.setItem('selectedKeys', str);
    },
    setOpenKeys: (state, log) => {
        state.openKeys = log;
        let str = JSON.stringify(log);
        sessionStorage.setItem('openKeys', str);
    },
    setTagViews: (state, log) => {
        let arr = [...state.tagViews];
        let n = arr.findIndex(ele => ele.path === log.path);
        if(n === -1) {
            arr.push(log)
        }
        state.tagViews = arr;
        let str = JSON.stringify(arr);
        sessionStorage.setItem('tagViews', str);
    },
    detTagViews: (state, log) => {
        let arr = [...state.tagViews];
        let n = arr.findIndex(ele => ele.path === log.path);
        arr.splice(n,1);
        state.tagViews = arr;
        let str = JSON.stringify(arr);
        sessionStorage.setItem('tagViews', str);
    },
    setWaybillIndex: (state, log) => {
        state.waybillIndex = log;
        sessionStorage.setItem('waybillIndex', log);
    },
    setAddressIndex: (state, log) => {
        state.addressIndex = log;
        sessionStorage.setItem('addressIndex', log);
    },
    setLoginStep: (state, log) => {
        state.loginStep = log;
    },
}

export default mutations