<!--
 * @Author: your name
 * @Date: 2021-12-08 13:45:46
 * @LastEditTime: 2021-12-08 14:11:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseProductName/index.vue
-->
<template>
    <div>
        <div><span>{{content}}</span><a class="btn-a" @click="openTable">选择品名</a></div>
        <a-modal :title="model.title" :visible="model.visible" :width="model.width" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" :maskClosable="false">
            <a-form-model layout="inline" :model="form">
                <a-form-model-item label="商品名称">
                    <a-input v-model="form.zhName" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button-group>
                        <a-button type="primary" @click="search">搜索</a-button>
                        <a-button @click="reset">重置</a-button>
                    </a-button-group>
                </a-form-model-item>
            </a-form-model>
            <a-table size="small" style="margin-top: 20px" :rowSelection="rowSelection" bordered :columns="columns" :data-source="tableData" :loading="tableLoading" :pagination="pagination" :rowKey="(record,index) => record.id" @change="handlerPage">
            </a-table>
        </a-modal>
    </div>
</template>

<script>
import { productItems } from "@/api/comm";
import columns from "./columns";
export default {
    name: "chooseProductName",
    props: {
        itemId: {
            type: [String,Number],
            default: ""
        }
    },
    model: {
        prop: "itemId",
        event: "returnItemId",
    },
    data() {
        return {
            content: "",
            apiUrl: productItems,
            confirmLoading: false,
            tableLoading: false,
            selectedRowKeys: [],
            model: {
                title: "",
                visible: false,
                width: "500px",
            },
            form: {
                zhName: "",
                limit: 20,
                page: 1,
            },
             pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            tableData: [],
            columns: columns,
        };
    },
    computed: {
        rowSelection() {
            return {
                type: "radio",
                columnTitle: "选择",
                columnWidth: 70,
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(
                        `selectedRowKeys: ${selectedRowKeys}`,
                        "selectedRows: ",
                        selectedRows
                    );
                    this.selectedRowKeys = selectedRowKeys;
                    let row = selectedRows[0];
                    this.content = `${row.zh_name}(id=${row.id}) `;
                },
            };
        },
    },
    methods: {
        openTable() {
            this.getData().then(() => {
                this.model.visible = true;
            });
        },
        getData() {
            return new Promise((resolve, reject) => {
                this.tableLoading = true;
                this.apiUrl(this.form)
                    .then((res) => {
                        this.tableData = res.data.list;
                        this.model.visible = true;
                        this.tableLoading = false;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            });
        },
        handleOk() {
            this.$emit("returnItemId", this.selectedRowKeys.toString()*1);
            this.$emit("change", this.selectedRowKeys[0]);
            this.model.visible = false;
        },
        handleCancel() {
            this.model.visible = false;
        },
        reset() {
            this.form.zhName = "";
        },
        search() {
            this.form.page = 1;
            this.getData();
        },
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
</style>
