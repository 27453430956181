/*
 * @Author: your name
 * @Date: 2021-10-12 13:43:03
 * @LastEditTime: 2021-10-12 13:48:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /data-show/src/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";
 const store = new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
export default store
