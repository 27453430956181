<!--
 * @Author: your name
 * @Date: 2021-11-18 17:15:53
 * @LastEditTime: 2021-11-29 17:40:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/App.vue
-->
<template>
  <a-config-provider :locale="locale">
    <div id="app" :class="[isBaseApp ? 'base-app' : '']">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: {
    isBaseApp() {
      return this.$route.path !== "/trackingQuery";
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  font-size: 62.5%;
}
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.base-app {
  min-width: 1600px;
  min-height: 760px;
}
</style>
